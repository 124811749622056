
import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/services/home/home.service';
@Component({
  selector: 'app-policy-and-privacy',
  templateUrl: './policy-and-privacy.component.html',
  styleUrls: ['./policy-and-privacy.component.scss'],
})
export class PolicyAndPrivacyComponent implements OnInit {
  privacy = {} as any;

  constructor(private homeService: HomeService) {}
  ngOnInit() {
    this.getPrivacyData();
  }
  getPrivacyData() {
    this.homeService.getFooterData().subscribe((res: any) => {
      this.privacy = res.company['privacy'];
    });
  }
}
