import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SnackbarService } from 'ngx-snackbar';
import { AuthService } from './../../../services/auth/auth.service';
import { HomeService } from 'src/app/services/home/home.service';
import { AccountService } from 'src/app/services/account/account.service';
import { CartService } from 'src/app/services/cart/cart.service';
import { NavigationEnd, Router } from '@angular/router';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { LangService, Language } from 'src/app/services/lang/lang.service';
import { TranslateService } from '@ngx-translate/core';
import { NgForm } from '@angular/forms';
import { ForgetPasswordComponent } from 'src/app/components/register/forget-password/forget-password.component';
import { DesignerConfirmationComponent } from 'src/app/components/register/designer-confirmation/designer-confirmation.component';
@Component({
  selector: 'app-sub-nav',
  templateUrl: './sub-nav.component.html',
  styleUrls: ['./sub-nav.component.scss'],
})
export class SubNavComponent implements OnInit {
  @ViewChildren('dropdownmenu') dropdownmenu: QueryList<ElementRef>;
  CARTITEMS: any[] = [];
  languages: Language[];
  currentLanguage: string;
  model = {} as any;
  isSingleLang: boolean;
  websiteLang: string;
  translateions: any;
  myFavoriteList: any[] = [];
  logged = sessionStorage.getItem('logged');
  designerTypes: any[] = [];
  loading = false;
  isVisible = true;
  registerDesigner = false;
  @ViewChild('loginForm') loginForm: NgForm;
  @ViewChild('brandRegister') brandRegister: NgForm;
  loginError: any;
  @Input() scrolled;
  isChecked: boolean;
  @Output() isCollapsed = new EventEmitter<boolean>();
  constructor(
    private router: Router,
    private langService: LangService,
    private translateService: TranslateService,
    private cartService: CartService,
    private accountService: AccountService,
    private homeService: HomeService,
    private authService: AuthService,
    private snackbarService: SnackbarService,
    private modalService: NgbModal,
    private renderer: Renderer2
  ) {
    this.currentLanguage = this.langService.language;
    this.cartService.Cart.subscribe((res) => {
      this.CARTITEMS = res;
    });
    this.accountService.Fav.subscribe((res) => {
      this.myFavoriteList = res['data'];
    });
    this.translateService.get('register_page').subscribe((res) => {
      this.translateions = res;
    });
  }
  ngOnInit(): void {
    this.setFavouritProducts();
    this.getFavourits();
    this.getDesginerTypes();
    this.setFavouritBrands();
  }
  onSubmit() {
    this.loading = true;
    this.loginError = null;
    let old = sessionStorage.getItem('userId');
    this.authService.loginUser(this.loginForm.value).subscribe(
      (res: any) => {
        if (res['status'] == true) {
          sessionStorage.setItem(
            'user',
            JSON.stringify(res['message']['user'])
          );
          sessionStorage.setItem('userId', res['message']['user']['id']);
          sessionStorage.setItem('logged', '1');
          this.loading = false;
          this.router.navigateByUrl('/account/overview');
          setTimeout(function () {
            window.location.reload();
          });
          this.cartService
            .edit_order_creator(old, res['message']['user']['id'])
            .subscribe((data) => {
              this.router.navigateByUrl('cart/shipping-step-one');
            });
          this.HideDropdownMenu();
        }
      },
      (err) => {
        this.loading = false;
        this.loginError = 'check your inputs.';
      }
    );
  }
  forgetPassword() {
    this.modalService
      .open(ForgetPasswordComponent, {
        centered: true,
        size: 'lg',
        keyboard: false,
        windowClass: 'animated fadeInDown',
      })
      .result.then(
        (result) => {},
        (reason) => {}
      );
  }
  logout() {
    sessionStorage.removeItem('logged');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('userId');
    this.router.navigateByUrl('/');
    setTimeout(function () {
      window.location.reload();
    });
  }
  showPass() {
    this.isVisible = false;
  }
  hiddenPass() {
    this.isVisible = true;
  }
  setFavouritProducts() {
    if (this.logged == '1') {
      const FavoriteList: any[] = JSON.parse(
        localStorage.getItem('favouritProducts')
      );
      if (FavoriteList != null) {
        FavoriteList.forEach((p) => {
          const data = {
            id: p.id,
            like: 1,
          };
          this.accountService.addFavourits(data).subscribe((res) => {});
        });
        localStorage.removeItem('favouritProducts');
      }
    }
  }
  getFavourits() {
    if (this.logged == '1') {
      this.accountService.getFavourits().subscribe((res: any) => {
        this.myFavoriteList = res['data'];
      });
    } else {
      this.myFavoriteList = this.accountService.favouritProducts;
    }
  }
  removeFromFavourit(product) {
    if (this.logged == '1') {
      const data = {
        id: product.id,
        like: 0,
      };
      this.accountService.addFavourits(data).subscribe((res) => {
        this.getFavourits();
      });
    } else {
      product.isFavourite = false;
      const index = this.myFavoriteList.findIndex((p) => p.id == product.id);
      this.myFavoriteList.splice(index, 1);
      localStorage.setItem(
        'favouritProducts',
        JSON.stringify(this.myFavoriteList)
      );
      this.myFavoriteList = JSON.parse(
        localStorage.getItem('favouritProducts')
      );
    }
  }
  setFavouritBrands() {
    if (this.logged == '1') {
      const favBrandsList: any[] = JSON.parse(
        localStorage.getItem('favouritBrands')
      );
      if (favBrandsList != null) {
        favBrandsList.forEach((p) => {
          const data = {
            id: p.id,
            like: 1,
          };
          this.accountService.setDesignerFavourit(data).subscribe((res) => {});
        });
        localStorage.removeItem('favouritBrands');
      }
    }
  }
  getDesginerTypes() {
    this.homeService.getBrandTypes().subscribe((res: any) => {
      this.designerTypes = res;
    });
  }
  registerSubmit() {
    this.loading = true;
    this.authService.registerBrand(this.brandRegister.value).subscribe(
      (res: any) => {
        this.loading = false;
        if (res.status == true) {
          this.registerDesigner = false;
          this.modalService.open(DesignerConfirmationComponent, {
              centered: true,
              size: 'lg',
              keyboard: false,
              windowClass: 'animated fadeInDown',
          });

          this.snackbarService.add({
            msg: this.translateions['done'],
            timeout: 4000,
            background: '#eee',
            color: '#333',
            action: {
              text: 'x',
              color: 'red',
            },
          });
        }
      },
      (error) => {
        this.snackbarService.add({
          msg: this.translateions['error'],
          timeout: 4000,
          background: '#eee',
          color: '#333',
          action: {
            text: 'x',
            color: 'red',
          },
        });
      }
    );
  }
  onLanguageChange(lang) {
    this.langService.language = lang;
    this.currentLanguage = this.langService.language;
  }
  search() {
    this.router.navigateByUrl('/search/' + this.model.search).then(() => {
      this.HideDropdownMenu();
    });
  }
  @HostListener('mouseover') onMouseOver(event) {
    this.dropdownmenu.toArray().forEach((element) => {
      this.renderer.removeStyle(element.nativeElement, 'display');
    });
  }
  @HostListener('mouseout') onMouseOut() {
    this.dropdownmenu.toArray().forEach((element) => {
      this.renderer.removeStyle(element.nativeElement, 'display');
    });
  }
  HideDropdownMenu() {
    this.dropdownmenu.toArray().forEach((element) => {
      this.renderer.setStyle(element.nativeElement, 'display', 'none');
    });
    this.isCollapsed.emit(true);
  }
}
