<div class="products">
    <div class="container">
        <div class="row">
            <div class="col text-center mt-4 mb-4">
                <h6><b>{{catData.name}}</b> </h6>
                <div [innerHTML]="catData.description" ></div>
            </div>
        </div>
    </div>
    <nav class="navbar navbar-expand-lg navbar-light" style="margin-bottom: 30px;">
        <div class="collapse navbar-collapse border-bottom" id="navbarSupportedContent">
            <ul class="navbar-nav ">
            </ul>
            <div class="my-2 my-lg-0" style=" display: none;">
                <ul class="navbar-nav text-right">
                    <li class="nav-item dropdown mmenu">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Sort by Price </a>
                        <div class="dropdown-menu row mt-0" aria-labelledby="navbarDropdown">
                            <form action="#" method="post" class="col-md-12">
                                <div class="col-md-6 my-1">
                                    <div class="custom-control custom-checkbox mr-sm-2">
                                        <label class="ff">Low : High <input type="radio" value="high" name="low">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6 my-1">
                                    <div class="custom-control custom-checkbox mr-sm-2">
                                        <label class="ff">High : Low <input type="radio" value="low" name="low">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="w-100 "></div>
                                <div class="w-100 d-flex justify-content-between my-4">
                                    <div class="col-md-5">
                                        <button class="btn btn-dark px-5 rounded-0" type="submit">
                                            Show Results</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <div class="container">
        <div class="row mt-4 mb-4" *ngIf="productsArr.length > 0">
            <div class="col-md-3 col-lg-3" *ngFor="let product of productsArr">
                <div class="card card-inverse border-none " *ngIf="product.total_quantity != 0">
                    <a [routerLink]="['/products/product', product.id]" *ngIf="product.images">
                        <img *ngIf="product.images.length < 2" class="card-img-top product-img-in-cat" [src]="product.images[0]">
                        <img *ngIf="product.images.length >= 2" class="card-img-top product-img-in-cat showimage" [src]="product.images[0]">
                        <img *ngIf="product.images.length >= 2" class="card-img-top product-img-in-cat hideimage" [src]="product.images[1]">
                    </a>
                    <blockquote class="card-blockquote pt-3 pb-3">
                        <div class="favourites-product-container">
                            <i *ngIf="product.isFavourite == true" (click)="removeFromFavourit(product)"
                                class="fas fa-heart" aria-hidden="true"></i>
                            <i *ngIf="product.isFavourite == false" (click)="addToFavourit(product)"
                                class="far fa-heart" aria-hidden="true"></i>
                        </div>
                        <div class="product-card-label">
                            <span *ngIf="product.total_quantity < 4">
                                <span style="color: #b80000" *ngIf="product.total_quantity == 0">
                                    {{'products.Sold_Out' | translate}} </span>
                                <span style="color: #a6a6a6" *ngIf=" product.total_quantity == 1">
                                    {{'products.Last_piece'|translate}} </span>
                                <span style="color: #a6a6a6" *ngIf=" product.total_quantity > 1">
                                    {{'products.last' | translate}}
                                    {{ product.total_quantity}} {{'products.pieces' | translate}} </span>
                            </span>
                        </div>
                        <div class="product-card-label">
                            <h6 class="text-muted"></h6>
                        </div>
                        <div class="product-card-title">
                            <p>
                                <a [routerLink]="['/products/product', product.id]">
                                    {{product.name}}
                                </a>
                            </p>
                            <p>{{product.sub_products[0].price + " " +product.sub_products[0].currency_id}} </p>
                        </div>
                    </blockquote>
                </div>
            </div>
        </div>
    </div>
</div>