<div class="homepage-clients">
    <h2 class="text-uppercase text-center" [innerHTML]="partenersData.title"></h2>
    <div class="container">
        <div class="partenters row" *ngIf="partenersData.items != 0">
            <div class="col-md-3 col-6 mt-2 mb-2" *ngFor="let partener of partenersData.items">
                <img [src]="partener.image_url">
            </div>
        </div>
    </div>
</div>
<div class="image-bottom">
    <img [src]="imgBottom.image" alt="">
</div>