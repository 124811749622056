<ngb-carousel #carousel [interval]="30000" [pauseOnHover]="pauseOnHover" [pauseOnFocus]="pauseOnFocus"
  (slide)="onSlide($event)">
  <ng-template ngbSlide *ngFor="let img of images; index as i">
    <div class="carousel-caption">
      <div class="about-button">
        <a (click)="open(content)">
          {{'brief_popup.about-title' | translate}}
        </a>
      </div>
    </div>
    <div class="picsum-img-wrapper">
      <img [src]="img" alt="My image description">
    </div>
  </ng-template>
</ngb-carousel>
<ng-template #content let-modal>
  <div class="modal-header border-bottom-none text-center">
    <h5 class="modal-title" id="modal-basic-title">
      {{aboutData.title}}
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body modal-body-custom text-center" [innerHTML]="aboutData.content">
  </div>
  <div class="modal-footer border-none">
    <a style="margin: auto auto" (click)="modal.close()" routerLink="/about/company">
      <button type="button" class="btn outline-button-modal btn-outline-secondary">
        {{'brief_popup.more' | translate}}
      </button>
    </a>
  </div>
</ng-template>
