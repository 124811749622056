import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from './../../services/account/account.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { ActivatedRoute } from '@angular/router';
import { LangService } from './../../services/lang/lang.service';
import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-designer',
  templateUrl: './designer.component.html',
  styleUrls: ['./designer.component.scss'],
})
export class DesignerComponent implements OnInit {
  websiteLang = '';
  brandData = {} as any;
  products: Array<any> = [];
  brandId: number;
  // favouritProducts: Array<any>;
  productsArr: Array<any> = [];
  logged = sessionStorage.getItem('logged');
  favouritBrands: Array<any>;
  constructor(
    private translate: LangService,
    private route: ActivatedRoute,
    private productsService: ProductsService,
    private accountService: AccountService,
    private spinner: NgxSpinnerService
  ) {
    this.websiteLang = this.translate.language;
  }
  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.brandId = +params.get('id')!;
      this.getBrand();
    });
    this.favouritBrands =
      JSON.parse(localStorage.getItem('favouritBrands')) || [];
  }
  getBrand() {
    this.spinner.show('sp');
    this.productsService.getBrandData(this.brandId).subscribe((res: any) => {
      this.brandData = res.designer_data;
      this.spinner.hide('sp');
      this.products = res.products;
      if (this.logged != '1') {
        this.products.forEach((pro) => {
          this.accountService.favouritProducts.forEach((fav) => {
            if (pro.id == fav.id) {
              pro.isFavourite = fav.isFavourite;
            }
          });
        });
      }
      this.productsArr = this.products;
    });
  }
  addFavouritBrand(id) {
    if (this.logged == '1') {
      const data = {
        id: id,
        like: 1,
      };
      this.accountService.setDesignerFavourit(data).subscribe((res) => {
        this.getBrand();
      });
    } else {
      this.brandData.isFavourite = true;
      this.brandData = { ...this.brandData };
      this.favouritBrands.push(this.brandData);
      localStorage.setItem(
        'favouritBrands',
        JSON.stringify(this.favouritBrands)
      );
    }
  }
  removeFavouritBrand(id) {
    if (this.logged == '1') {
      const data = {
        id: id,
        like: 0,
      };
      this.accountService.setDesignerFavourit(data).subscribe((res) => {
        this.getBrand();
      });
    } else {
      this.brandData.isFavourite = false;
      this.brandData = { ...this.brandData };
      this.favouritBrands.push(this.brandData);
      localStorage.setItem(
        'favouritBrands',
        JSON.stringify(this.favouritBrands)
      );
    }
  }
  addToFavourit(product) {
    if (this.logged == '1') {
      const data = {
        id: product.id,
        like: 1,
      };
      this.accountService.addFavourits(data).subscribe((res) => {
        this.getBrand();
        this.accountService.favDetails();
      });
    } else {
      this.products.forEach((p) => {
        if (p.id == product.id) {
          p.isFavourite = true;
        }
      });
      this.productsArr = this.products;
      product.isFavourite = true;
      this.accountService.favouritProducts.push(product);
      localStorage.setItem(
        'favouritProducts',
        JSON.stringify(this.accountService.favouritProducts)
      );
    }
  }
  removeFromFavourit(product) {
    if (this.logged == '1') {
      const data = {
        id: product.id,
        like: 0,
      };
      this.accountService.addFavourits(data).subscribe((res) => {
        this.getBrand();
        this.accountService.favDetails();
      });
    } else {
      this.products.forEach((p) => {
        if (p.id == product.id) {
          p.isFavourite = false;
        }
      });
      product.isFavourite = false;
      this.productsArr = this.products;
      const index = this.accountService.favouritProducts.findIndex(
        (p) => p.id == product.id
      );
      this.accountService.favouritProducts.splice(index, 1);
      localStorage.setItem(
        'favouritProducts',
        JSON.stringify(this.accountService.favouritProducts)
      );
    }
  }
}
