import { CartService } from './../../../services/cart/cart.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment.prod';
import { Router } from '@angular/router';
@Component({
  selector: 'app-shipping-bag',
  templateUrl: './shipping-bag.component.html',
  styleUrls: ['./shipping-bag.component.scss'],
})
export class ShippingBagComponent implements OnInit {
  data: [];
  img_url = environment.imgURL;
  AvilableCouantity = 20;
  loopedArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  total: number = 0;
  saving: boolean;
  constructor(
    private service: CartService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {}
  ngOnInit() {
    this.getCart();
  }
  updatetotal() {
    this.InitFields();
  }
  getCart() {
    this.service.Cart.subscribe((res) => {
      this.data = res;
      this.InitFields();
    });
  }
  InitFields() {
    this.total = 0;
    this.data.forEach((element: any) => {
      element['SelctedSubProduct'] = element.Product.sub_products.find(
        (x) => x.id == element['sub_product_id']
      );
      element['loopedArray'] = [];
      element['quantity'] = Number(element['quantity']);
      element['price_id'] = Number(element.price_id);  
      if (element['share'] == 1) {
        element['price_id'] = Number(element.Product.show_at_home_price);  
      }
      
      element['SelctedSubProduct']['quantity'] = Number(
        element['SelctedSubProduct']['quantity']
      );
      element['SelctedSubProduct']['price'] = Number(
        element['SelctedSubProduct']['price']
      );
      element['price_id'] =
        element['quantity'] * element['price_id'];
      this.total = this.total + Number(element['price_id']);
      for (
        let index = 1;
        index <= element['SelctedSubProduct']['quantity'];
        index++
      ) {
        element['loopedArray'].push(index);
      }
      this.cdr.markForCheck();
    });
    console.log(this.data);
  }
  onSubmit() {
    this.data.forEach((element: any) => {
      this.service.edit_quantity(element).subscribe((res) => {
        console.log(res);
        this.router.navigateByUrl('cart/annonymous-login');
        this.service.cartDetails();
      });
    });
  }
  removeItem(id) {
    this.service.remove_item(id).subscribe((res) => {
      console.log(res);
      this.service.cartDetails();
    });
  }
}
