<div class="modal-body p-0">
    <div class="row">
        <div class="col-6">
            <img [src]="productData.images[0]" alt="">
            
        </div>
        <div class="col-6">
            <div class="info">
                <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4> {{'products.Share_this_item' | translate}}
                </h4>
                <h3>
                    {{productData.name}}
                </h3>
                <a target="_blank" [href]="'https://twitter.com/intent/tweet?'+
                    'hashtags=brwazsa&amp;ref_src=twsrc%5Etfw&amp;related=brwaz%2Cbrwaz&amp;text=' +
                    productData.name+
                    '&amp;tw_p=tweetbutton&amp;url=https://brwaz.com/#/products/product/${productData.id}'+
                    productData.id+
                    ';via=@brwazsa'">
                    <i class="fab fa-twitter"></i>



                </a>



            </div>
        </div>
    </div>
</div>