import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from 'src/app/services/account/account.service';
import { LangService } from './../../../services/lang/lang.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-account-orders',
  templateUrl: './account-orders.component.html',
  styleUrls: ['./account-orders.component.scss'],
})
export class AccountOrdersComponent implements OnInit {
  myCart: Array<any> = [];
  websiteLang = '';
  products: any;
  constructor(
    private router: Router,
    private translate: LangService,
    private accountService: AccountService,
    private spinner:NgxSpinnerService
  ) {
    if (sessionStorage.getItem('logged') != '1') {
      this.router.navigateByUrl('/');
    }
    this.websiteLang = this.translate.language;
  }
  ngOnInit() {
    this.getAllOrders();
  }
  getAllOrders() {
    this.spinner.show('sp');
    this.accountService.getOrders().subscribe((res: any) => {
      this.myCart = res;
      this.myCart.forEach((element) => {
        while (element.id.length < 6) element.id = "0" + element.id;
      });
      this.myCart = [...this.myCart]
      this.spinner.hide('sp');
    });
  }
}
