<div class="products">
    <div class="container">
        <div class="row">
            <div class="col text-center mt-4 mb-4">
                <h5 class="">All products</h5>
                <p>
                    Celebrated silhouettes reconstructed with new runway details and refined fabrics, from double-faced
                    wool to ECONYL® – a sustainable nylon yarn.
                </p>
            </div>
        </div>
        <div class="row mt-4 mb-4">
            <div class="col-md-3" *ngFor="let product of productsArr">
                <div class="card card-inverse border-none">
                    <a [routerLink]="['/products/product', product.id]">
                        <div *ngFor="let img of product.images | slice:0:1; let i = index">
                            <img class="card-img-top" [src]="img">
                        </div>
                    </a>
                    <blockquote class="card-blockquote pt-3 pb-3">
                        <div class="favourites-product-container">
                            <i *ngIf="product.isFavourite == true" (click)="removeFromFavourit(product)"
                                class="fas fa-heart" aria-hidden="true"></i>
                            <i *ngIf="product.isFavourite == false" (click)="addToFavourit(product)"
                                class="far fa-heart" aria-hidden="true"></i>
                        </div>
                        <div class="product-card-label" [routerLink]="['/products/product', product.id]"
                            style="cursor: pointer;">
                            <h6 class="text-muted">{{product.name}}</h6>
                            <!-- <p>{{product.sub_products[0]['price'] + " " +product.sub_products[0]['currency_id']}} </p> -->
                        </div>
                    </blockquote>
                </div>
            </div>
        </div>
    </div>
</div>