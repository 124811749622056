import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/services/home/home.service';
// For jquery
declare var $: any;
@Component({
  selector: 'app-homepage-clients',
  templateUrl: './homepage-clients.component.html',
  styleUrls: ['./homepage-clients.component.scss'],
})
export class HomepageClientsComponent implements OnInit {
  partenersData = {}  as any;
  imgBottom = {}  as any;
  constructor(private homeService: HomeService) {}
  ngOnInit() {
    this.getPartentersData();
  }
  getPartentersData() {
    this.homeService.getHomeData().subscribe((res: any) => {
      this.partenersData = res.partener;
      this.imgBottom = res.bottom
    });
  }
}
