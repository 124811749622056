<div class="modal-body">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
    <br>
    <h3 class="text-center" >{{'products.Size_guide' | translate}}</h3>
    <br>
    <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <li [ngbNavItem]="1">
          <a ngbNavLink>{{'products.Inches' | translate}}</a>
          <ng-template ngbNavContent>
         <img [src]="productData.inch" alt="">
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink>{{'products.Centimeters' | translate}}</a>
          <ng-template ngbNavContent>
            <img [src]="productData.cm" alt="">
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>