<div class="modal-body p-0">
    <div class="row">
        <div class="col-6">
            <img [src]="productData.images[0]" alt="">
        </div>
        <div class="col-6">
            <div class="info">
                <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4>{{'products.Watch_at_home' | translate}}</h4>
                <h3>
                    {{productData.name}}
                </h3>
                <p>
                    {{'products.show_at_home_prag' | translate}}
                </p>
                <button type="button" id="bag" class="btn btn-dark px-5 rounded-0" (click)="AddToBag()">
                    {{'products.Add' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>