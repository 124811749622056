import { NgxSpinnerService } from 'ngx-spinner';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CartService } from './../../../services/cart/cart.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-sercure-payment',
  templateUrl: './sercure-payment.component.html',
  styleUrls: ['./sercure-payment.component.scss'],
})
export class SercurePaymentComponent implements OnInit {
  data: any;
  PaymentWay = 'card';
  total: any = 0;
  OrderId = '';
  PaymentDone = false;
  User = {};
  isChecked: boolean = false;
  cardnumber:any;
  cardname:any;
  cv:any;
  month:any;
  year:any
  success:any
  paymentID:any
  invoiceID:any
  constructor(
    private router: Router,
    private service: CartService,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
  ) {}
  ngOnInit(): void {
    this.getUserData();
    this.getCart();
    this.route.queryParams.subscribe((params) => {
      if (params.paymentId != undefined) {
        let paymentdata = {
          user_id: this.User['id'],
          paymentId: params.paymentId,
          status: params.success,
          invoiceID: params.Id
        };
        this.service.SavePaymentData(paymentdata).subscribe((res) => {
          if(res['status'] == 'false'){
            alert(res['message']);
          }
          if (params.success != "false") {
            this.OrderId
            this.padLeadingZeros(res['order']);
            this.PaymentDone = params.success;
          }
        });
      }
        
    });
  }
  padLeadingZeros(num) {
    let s = num + '';
    while (s.length < 6) s = '0' + s;
    return s;
  }
  getUserData() {
    if (!sessionStorage.getItem('user')) {
      this.router.navigateByUrl('/cart');
    } else {
      this.User = JSON.parse(sessionStorage.getItem('user'));
      console.log(this.User);
    }
  }
  getCart() {
    this.service.Cart.subscribe((res) => {
      this.data = res;
      this.total = 0;
      this.data.forEach((element: any) => {
        element['SelctedSubProduct'] = element.Product.sub_products.find(
          (x) => x.id == element['sub_product_id']
        );
        element['loopedArray'] = [];
        element['quantity'] = Number(element['quantity']);
        element['price_id'] = Number(element['price_id']);
        element['SelctedSubProduct']['quantity'] = Number(
          element['SelctedSubProduct']['quantity']
        );
        element['SelctedSubProduct']['price'] = Number(
          element['SelctedSubProduct']['price']
        );
        element['price_id'] =
          element['quantity'] * element['SelctedSubProduct']['price'];
          if (element['share'] == 1) {
            element['price_id'] = Number(element.Product.show_at_home_price);  
          }
        this.total = this.total + Number(element['price_id']);
        for (
          let index = 1;
          index <= element['SelctedSubProduct']['quantity'];
          index++
        ) {
          element['loopedArray'].push(index);
        }
        this.cdr.markForCheck();
      });
      console.log(this.data);
    });
  }
  Finish() {
    let error= true;
    if(this.PaymentWay =='card'){
      if(this.cardnumber == undefined){
        alert('Please fill the card Number');
        error = false;
      }else if(this.cardname == undefined){
        alert('Please fill the card Name');
        error = false;
      }else if(this.cv == undefined){
        alert('Please fill the card CV');
        error = false;
      }else if(this.month == undefined){
        alert('Please fill the card month');
        error = false;
      }else if(this.year == undefined){
        alert('Please fill the card year');
        error = false;
      }
    }
    let body = {
      user_id: this.User['id'],
      status: 1,
      total: this.total,
      payment: this.PaymentWay,
      cardnumber:this.cardnumber,
      cardname:this.cardname,
      cv:this.cv,
      month:this.month,
      year:this.year,
    };
    
    if (this.PaymentWay != "card"){
      this.spinner.show('sp');
      this.service.finishOrder(body).subscribe((res) => {
        this.spinner.hide('sp');
        this.OrderId = this.padLeadingZeros(res['order']);
        this.PaymentDone = true;
      });
    } else if (error) {
      this.spinner.show('sp');
      this.service.finishOrder(body).subscribe((res) => {
        this.spinner.hide('sp');
        window.location.href = res['payment'].link;
      });
    }

  }
  backtohome() {
    this.service.cartDetails();
    this.router.navigateByUrl('/');
  }
}
