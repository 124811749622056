import { AccountService } from './../../../services/account/account.service';
import { Component, OnInit } from '@angular/core';
import { ProductsService } from './../../../services/products/products.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  products: Array<any> = [];
  // favouritProducts: Array<any>
  productsArr: Array<any> = [];
  logged = sessionStorage.getItem('logged');
  constructor(
    private productsService: ProductsService,
    private accountService: AccountService,
    private spinner: NgxSpinnerService
  ) {}
  ngOnInit() {
    this.getAllProducts();
  }
  getAllProducts() {
    this.spinner.show('sp');
    this.productsService.allProducts().subscribe((res: any) => {
      this.products = res;
      this.spinner.hide('sp');
      if (this.logged != '1') {
        this.products.forEach((pro) => {
          this.accountService.favouritProducts.forEach((fav) => {
            if (pro.id == fav.id) {
              pro.isFavourite = fav.isFavourite;
            }
          });
        });
      }
      this.productsArr = this.products;
    });
  }
  addToFavourit(product) {
    if (this.logged == '1') {
      const data = {
        id: product.id,
        like: 1,
      };
      this.accountService.addFavourits(data).subscribe((res) => {
        this.getAllProducts();
        this.accountService.favDetails();
      });
    } else {
      this.products.forEach((p) => {
        if (p.id == product.id) {
          p.isFavourite = true;
        }
      });
      this.productsArr = this.products;
      product.isFavourite = true;
      this.accountService.favouritProducts.push(product);
      localStorage.setItem(
        'favouritProducts',
        JSON.stringify(this.accountService.favouritProducts)
      );
    }
  }
  removeFromFavourit(product) {
    if (this.logged == '1') {
      const data = {
        id: product.id,
        like: 0,
      };
      this.accountService.addFavourits(data).subscribe((res) => {
        this.getAllProducts();
        this.accountService.favDetails();
      });
    } else {
      this.products.forEach((p) => {
        if (p.id == product.id) {
          p.isFavourite = false;
        }
      });
      product.isFavourite = false;
      this.productsArr = this.products;
      const index = this.accountService.favouritProducts.findIndex(
        (p) => p.id == product.id
      );
      this.accountService.favouritProducts.splice(index, 1);
      localStorage.setItem(
        'favouritProducts',
        JSON.stringify(this.accountService.favouritProducts)
      );
    }
  }
}
