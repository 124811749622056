import { HomeService } from 'src/app/services/home/home.service';
import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss'],
})
export class LegalComponent implements OnInit {
  legal = {} as any;
  constructor(private homeService: HomeService) {}
  ngOnInit() {
    this.getLegalData();
  }
  getLegalData() {
    this.homeService.getFooterData().subscribe((res: any) => {
      this.legal = res.company['legel'];
    });
  }
}
