<div class="my-profile ">
  <div class="container">
    <div class="row">
      <div class="offset-md-2"></div>
      <div class="designerInfo col-md-5">
        <div class="designer-details">
          <h1 class="title">{{brandData.store_name}}</h1>
          <h3 class="title text-lowercase">
            {{brandData.category}}
          </h3>
          <img src="assets/img/under.png">
        </div>
        <div class="designer-img">
          <img [src]="brandData.image" alt="">
        </div>
      </div>
      <div class="col-md-2 designer-fav">
        <i *ngIf="brandData.isFavourite == true" (click)="removeFavouritBrand(brandData.id)" class="fas fa-heart"
          aria-hidden="true"></i>
        <i *ngIf="brandData.isFavourite == false" (click)="addFavouritBrand(brandData.id)" class="far fa-heart"
          aria-hidden="true"></i>
      </div>
      <div class="col-md-3 designer-contact">
        <div class="dropbtn nav-link head">
          {{(websiteLang == 'en')? 'Contacts' : 'بيانات الاتصال'}} </div>
        <ul class="social-list">
          <li *ngIf="brandData.snap != ''">
              <i class="fab fa-snapchat-ghost " aria-hidden="true"></i>
            {{brandData.snap}}
          </li>
          <li *ngIf="brandData.twitter != ''">
            <a [href]="'https://twitter.com/' + brandData.twitter" target="_blank">
              <i class="fab fa-twitter" aria-hidden="true"></i>
            </a>
            {{brandData.twitter}}
          </li>
          <li *ngIf="brandData.instagram != ''">
            <a [href]="'https://instagram.com/'+brandData.instagram" target="_blank">
              <i class="fab fa-instagram" aria-hidden="true"></i>
            </a>
            {{brandData.instagram}}
          </li>
        </ul>
        <!-- <div>
          <a class="email" href="mainto:{{brandData.email}}">{{brandData.email}}</a>
        </div> -->
      </div>
    </div>
  </div>
</div>
<div class="container" style="padding-top: 50px;">
  <div class="row" *ngIf="brandData.note">
    <div class=" col-md-12" style="margin: 50px auto;">
      <div class="dropbtn nav-link head" style="font-weight: 600; padding-left: 0px;">
        {{'brief_popup.about-title'|translate}} </div>
        <div class="about-designer" [innerHTML]="brandData.note" ></div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3 mt-5" *ngFor="let product of productsArr">
      <div class="card border-none">
        <a [routerLink]="['/products/product', product.id]">
          <img class="card-img-top product-img-in-cat" [src]="product.images[0]">
        </a>
        <blockquote class="card-blockquote pt-3 pb-3">
          <div class="product-card-label" *ngFor="let sub of product.sub_products | slice:0:1; let i = index">
            <span *ngIf="sub['quantity'] < 4">
              <span style="color: #b80000" *ngIf="sub['quantity'] == 0"> {{'products.Sold_Out' |
                translate}} </span>
              <span style="color: #a6a6a6" *ngIf="sub['quantity'] == 1"> {{'products.Last_piece' |
                translate}} </span>
              <span style="color: #a6a6a6" *ngIf="sub['quantity'] > 1"> {{'products.last' | translate}}
                {{sub.quantity}} {{'products.pieces' | translate}} </span>
            </span>
          </div>
          <div class="favourites-product-container">
            <i *ngIf="product.isFavourite == true" (click)="removeFromFavourit(product)" class="fas fa-heart"
              aria-hidden="true"></i>
            <i *ngIf="product.isFavourite == false" (click)="addToFavourit(product)" class="far fa-heart"
              aria-hidden="true"></i>
          </div>
          <div class="product-card-title" [routerLink]="['/products/product', product.id]">
            <p>
              <b> {{product.name}}</b>
            </p>
            <p *ngFor="let sub of product.sub_products | slice:0:1; let i = index">
              <span *ngIf="sub['quantity'] == 0" class="crossed" >{{ sub['price'] }}</span>
              <span *ngIf="sub['quantity'] != 0" >{{ sub['price'] }}</span>
              {{" " + sub['currency_id']}}
            </p>
          </div>
        </blockquote>
      </div>
    </div>
  </div>
</div>
