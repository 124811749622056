export const environment = {
  production: true,
  baseURL: 'https://api.brwaz.com',
//   baseURL: 'http://localhost/brwazApi/',
  imgURL: 'http://admin.brwaz.com/Uploads/',
  siteURL: 'http://brwaz.com/#/'

// baseURL: 'http://localhost/BrwazAPI',
// //   baseURL: 'http://localhost/brwazApi/',
//   imgURL: 'http://localhost/BrwazAdmin/Uploads/',
//   siteURL: 'http://brwaz.com/#/'
};
