import { HomeService } from 'src/app/services/home/home.service';
import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-about-company',
  templateUrl: './about-company.component.html',
  styleUrls: ['./about-company.component.scss'],
})
export class AboutCompanyComponent implements OnInit {
  aboutData = {} as any;
  constructor(
    private homeService: HomeService
  ) {}
  ngOnInit() {
    this.getAboutCompany()
  }
  getAboutCompany() {
    this.homeService.getFooterData().subscribe((res: any) => {
      this.aboutData = res.company['about'];
    });
  }
}
