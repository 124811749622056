import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-size-guide',
  templateUrl: './size-guide.component.html',
  styleUrls: ['./size-guide.component.scss'],
})
export class SizeGuideComponent implements OnInit {
  @Input() productData = {} as any;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
