<div class="cart">
    <div class="shopping-bag">
        <div class="container" *ngIf="!PaymentDone">
            <h2 class="h2 shopping-bag-title text-uppercase"> {{'cart.Secure_payment'|translate}}</h2>
            <p class="shopping-bag-brief">
                {{'cart.method'|translate}}
            </p>
            <div class="app-loader"></div>
            <div class="shopping-bag-body">
                <div class="row">
                    <div class="col-12 col-lg-9">
                        <div class="left-side">
                            <form action="https://brwaz.com/my_cart/finish/en" method="post">
                                <div class="pay-by">
                                    <div class="custom-control custom-radio ">
                                        <input value="card" type="radio" style="width: auto;" [checked]="PaymentWay"
                                            [(ngModel)]="PaymentWay" id="customRadioInline2" name="customRadioInline2">
                                        <label>{{'cart.credit'|translate}} </label>
                                    </div>
                                    <div class="payment-platforms">
                                        <a></a>
                                        <a></a>
                                        <a></a>
                                    </div>
                                    <div class="custom-control custom-radio ">
                                        <input value="delivary" type="radio" style="width: auto;" [checked]="PaymentWay"
                                            [(ngModel)]="PaymentWay" id="customRadioInline2" name="customRadioInline2">
                                        <label>{{'cart.delivery'|translate}} </label>
                                    </div>
                                    <div class="payment-platforms">
                                        <a class="text-uppercase text-center">{{'cart.cash'|translate}}</a>
                                    </div>
                                </div>
                                <hr>
                                <div class="card-details" id="pay" *ngIf="PaymentWay == 'card'">
                                    <div class="row">
                                        <div class="col-12 col-lg-8">
                                            <h4 class="h4">
                                                {{'cart.Card_details'|translate}} </h4>
                                            <div class="form-group">
                                                <label>
                                                    {{'cart.Card_Number'|translate}} * </label>
                                                <input id="nu" name="cardnumber" value="" type="text"
                                                    class="form-control" [(ngModel)]="cardnumber" required="">
                                                <div class="info-icon">
                                                    <i class="fa fa-question" aria-hidden="true"></i>
                                                    <span class="info-message">
                                                        {{'cart.cardReq'|translate}} </span>
                                                </div>
                                                <div class="clearfix"></div>
                                            </div>
                                            <div class="form-group">
                                                <label> {{'cart.Name_card'|translate}} * </label>
                                                <input name="cardname" id="nu2" value="" type="text"
                                                    class="form-control" [(ngModel)]="cardname" required="">
                                                <div class="info-icon">
                                                    <i class="fa fa-question" aria-hidden="true"></i>
                                                    <span class="info-message">
                                                        {{'cart.nameReq'|translate}} </span>
                                                </div>
                                                <div class="clearfix"></div>
                                            </div>
                                            <div class="form-group">
                                                <div clss="row">
                                                    <div class="form-group mb-0">
                                                        <label>
                                                            CCV * </label>
                                                        <input id="nu4" name="cv" value="" type="text" max="999"
                                                            maxlength="3" [(ngModel)]="cv" class="form-control" required="">
                                                        <div class="info-icon">
                                                            <i class="fa fa-question" aria-hidden="true"></i>
                                                            <span class="info-message" style="height: 103px;">
                                                                <img src="assets/img/ccv.jpeg"
                                                                    style="height: 100px;" alt="">
                                                            </span>
                                                        </div>
                                                        <div class="clearfix"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="row">
                                                    <label for="formGroupExampleInput" class=" col-md-12">
                                                        {{'cart.Expiry_Date'|translate}} * </label>
                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group mb-0">
                                                            <input id="nu3" value="" name="month" placeholder="{{'cart.month'|translate}}"
                                                                maxlength="2" [(ngModel)]="month" type="text" class="form-control"
                                                                required="">
                                                            <div class="info-icon">
                                                                <i class="fa fa-question" aria-hidden="true"></i>
                                                                <span class="info-message">
                                                                    {{'cart.dateReq'|translate}} </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group mb-0">
                                                            <input id="nu3" value="" name="year" placeholder="{{'cart.year'|translate}}"
                                                                maxlength="2" [(ngModel)]="year"  type="text" class="form-control"
                                                                required="">
                                                            <div class="info-icon">
                                                                <i class="fa fa-question" aria-hidden="true"></i>
                                                                <span class="info-message">
                                                                    {{'cart.dateReq'|translate}} </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="cash" class="left-side" *ngIf="PaymentWay == 'delivary'">
                                    <div class="row">
                                        <div class="col-12 col-lg-8">
                                            <h4 class="h4">
                                                {{'cart.delivery'|translate}} </h4>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="order-details">
                                    <div class="row">
                                        <div class="col-12 col-md-4">
                                            <div class="detaiLS">
                                                <h6 class="h6">
                                                    {{'cart.Order_details'|translate}} </h6>
                                                <p>
                                                </p>
                                                <div>
                                                    {{'cart.Your_email'|translate}} </div>
                                                <div>{{User['email']}}</div>
                                                <p></p>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-4">
                                            <div class="detaiLS">
                                                <h6 class="h6">
                                                    {{'cart.Shipping_method'|translate}} </h6>
                                                <p>
                                                    {{'cart.Express_delivery'|translate}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-4">
                                            <div class="detaiLS">
                                                <h6 class="h6">
                                                    {{'cart.Billing_Address'|translate}} </h6>
                                                <p>
                                                    {{User['address']}} <br /> {{User['address2']}} <br />
                                                    {{User['country_id']}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-4">
                                            <div class="detaiLS">
                                                <h6 class="h6">
                                                    {{'cart.Billing_Address'|translate}}</h6>
                                                <p>
                                                    {{User['address']}} <br /> {{User['address2']}}
                                                    <br /> {{User['post_code']}}
                                                    <br /> {{User['country_id']}}
                                                    <br /> {{User['country_code']}}/{{User['phone']}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="order-items">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <tbody>
                                                <tr class="text-center">
                                                    <td> {{'cart.Item'|translate}} </td>
                                                    <td> {{'cart.Description'|translate}}</td>
                                                    <td>{{'cart.Price'|translate}}</td>
                                                    <td>{{'cart.Quantity'|translate}}</td>
                                                    <td>{{'cart.Sub-total'|translate}}</td>
                                                </tr>
                                                <tr *ngFor="let item of data; let i = index">
                                                    <td>
                                                        <img src="{{item.Product.images[0]}}" alt=".." title="...">
                                                    </td>
                                                    <td class="description">
                                                        <p class="item-info">
                                                            {{item.Product.name}}
                                                        </p>
                                                        <div class="color">
                                                            <span>
                                                                {{'products.Color'|translate}} :
                                                            </span>
                                                            <span>{{item.SelctedSubProduct.color.color}} </span>
                                                        </div>
                                                        <div class="size">
                                                            <span>
                                                                {{'products.Size'|translate}} : </span>
                                                            <span>{{item.SelctedSubProduct.size.size}} </span>
                                                        </div>
                                                        <div class="size" *ngIf="item.share == '1'">
                                                            <span>{{'products.Watch_at_home'|translate}} </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <a class="price">{{item.SelctedSubProduct.currency_id}}
                                                            <span id="price{{item.id}}" *ngIf="item.share == '1'" >
                                                                {{item.Product.show_at_home_price}}
                                                            </span>
                                                            <span id="price{{item.id}}" *ngIf="item.share == '0'" >
                                                                {{item.SelctedSubProduct.price}}
                                                            </span>
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <span>{{item['quantity']}}</span>
                                                    </td>
                                                    <td>
                                                        <input type="hidden" id="0" value="14000">
                                                        <a class="sub-total">{{item.SelctedSubProduct.currency_id}}
                                                            <span id="t194" *ngIf="item.share == '1'" >
                                                                {{item.Product.show_at_home_price}}
                                                            </span>
                                                            <span id="t194" *ngIf="item.share == '0'" >
                                                                {{item.price_id}}
                                                            </span>
                                                            
                                                            </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="total-price">
                                        <div class="row">
                                            <div class="col-md-4"></div>
                                            <div class="col-6 col-md-4">
                                                <div class="titles">
                                                    <p class="sub-total">
                                                        {{'cart.Sub-total'|translate}} </p>
                                                    <p class="sub-total">
                                                        {{'cart.Shipping'|translate}}   </p>
                                                    <p class="sub-total">
                                                        5% {{'cart.VAT'|translate}} </p>
                                                    <p class="estimated-total">
                                                        {{'cart.Estimated_Total'|translate}}  </p>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-4 text-right">
                                                <div class="prices">
                                                    <p class="sub-total"> SAR <span id="sub">{{total}}</span> </p>
                                                    <p class="sub-total"> <span id="sub"> {{'cart.Free_shipping'|translate}}</span> </p>
                                                    <p class="estimated-total"> SAR <span id="sub2">--</span></p>
                                                    <p class="estimated-total"> SAR <span id="sub2"> {{total}}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="confirmation">
                                    <h6 class="h6">
                                        {{'cart.terms'|translate}}
                                    </h6>
                                    <label class="container">
                                        {{'cart.condition'|translate}}
                                        <input type="checkbox" [(ngModel)]="isChecked"
                                            [ngModelOptions]="{standalone: true}">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <input type="hidden" name="email" value="iemam@programmingmap.com">
                                <input type="hidden" name="curncy" value="SAR">
                                <input type="hidden" name="subtotal" value="14000">
                                <input type="hidden" name="shipping_cost" value="0">
                                <input type="hidden" name="total" value="14700">
                                <input type="hidden" name="id" value="122">
                                <div class="order-and-pay text-right">
                                    <a routerLink="/cart/shipping-step-one" class="text-uppercase prev-step">
                                        {{'cart.Previous_step'|translate}}
                                        </a>
                                    <button type="submit" class="btn btn-dark rounded-0 text-uppercase"
                                        (click)="Finish()" [disabled]="isChecked == false">
                                        {{'cart.order'|translate}} </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3">
                        <app-cart-aside></app-cart-aside>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" *ngIf="PaymentDone">
            <div class="d-flex justify-content-center align-items-center mt-5">
                <h2 class="h2 shopping-bag-title text-uppercase"> {{'cart.id'|translate}}#B{{OrderId}}
                </h2>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-5 mb-5">
                <button type="button" id="bag" class="btn btn-dark px-5 rounded-0" (click)="backtohome()">
                    {{'cart.RETURN_TO_STORE'|translate}}
                </button>
            </div>
        </div>
    </div>
</div>